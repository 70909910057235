import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import format from 'date-fns/format';

import { getImage } from '../../../../utils/sanityTypes';
import { PostTeaserCardPropsItem } from '../../PostTeasersCarousel/types';

import { isOfSanityPostType } from './utils';
import { usePagePropsContext } from '../../../../contexts/PageDataContext';

export const usePostId = () => {
  const pageContext = usePagePropsContext();
  const postId = useMemo(() => {
    if (!isOfSanityPostType(pageContext)) {
      return undefined;
    }

    return pageContext.sanityPost?.id;
  }, [pageContext]);

  return postId;
};


export const useLatestBlogs = (postId: string): PostTeaserCardPropsItem[] => {
  const data = useStaticQuery<GatsbyTypes.LatestBlogsQuery>(graphql`
    query LatestBlogs {
      allSanityPost(limit: 10, sort: { fields: publishDate, order: DESC }) {
        nodes {
          id
          title
          subtitle
          i18n_lang
          images {
            _key
            mobileImage {
              ...ImageFragment
            }
            image {
              ...ImageFragment
            }
          }
          firstName
          lastName
          publishDate
          slug {
            current
          }
        }
      }
    }
  `);

  const {
    allSanityPost: { nodes },
  } = data;

  return useMemo(
    () =>
      nodes
        ?.filter((post) => post.id !== postId)
        .map((post) => {
          const {
            id,
            publishDate,
            images,
            title: postTitle,
            firstName,
            i18n_lang,
            lastName,
            slug,
          } = post ?? {};
          const { mobileImage, image } = images?.[0] || {};

          return {
            key: id ?? '',
            image: mobileImage ? getImage(mobileImage) : getImage(image),
            title: postTitle ?? '',
            date: publishDate ? format(new Date(publishDate), 'dd MMMM, yyyy') : '',
            link: { to: slug?.current ?? '' },
            author: { firstName: firstName ?? '', lastName: lastName ?? '' },
            i18n_lang: i18n_lang ?? ''
          };
        }),
    [nodes, postId]
  );
};
