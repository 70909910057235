import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { Inner } from '../../styles/Grid/styles';
import { settings } from './const';
import * as Styled from './styles';
import { PostTeasersCarouselDataProps } from './types';
import { LinkMode } from '../../atoms/Link/enums';
import PostTeaserCard from '../../molecules/PostTeaserCard';
import Link from '../../atoms/Link';
import { useCurrentLocale } from '../../../contexts/LocaleContext/hooks';

const PostTeasersCarousel: React.FC<PostTeasersCarouselDataProps> = ({ data }) => {
  const { title, link: to, linkText, items } = data;
  const currentLanguage = useCurrentLocale();
  return (
    <Inner>
      <Styled.PostTeasersCarouselHeading
        {...{ title }}
        content={
          <Link mode={LinkMode.WithArrowRight} {...{ to }}>
            {linkText}
          </Link>
        }
      />
      {items.length && (
        <Styled.PostTeasersCarouselWrapper>
          <Slider {...settings}>

            {/* We need to filter out all the items that are of another language. 
            It is not possible to filter the query in the parent as it is static */}
            {items.filter((v) => { 
              return v.i18n_lang == currentLanguage;
          }).map(({ key, ...restCardProps }) => (
                  <Styled.PostTeasersCarouselItem {...{ key }} >
                    <PostTeaserCard {...{ ...restCardProps }} />
                  </Styled.PostTeasersCarouselItem>
            ))}
          </Slider>
        </Styled.PostTeasersCarouselWrapper>
      )
      }
    </Inner >
  );
};

export default PostTeasersCarousel;
