import { graphql } from 'gatsby';
import React from 'react';
import format from 'date-fns/format';

import { getHref, getImage } from '../../../../utils/sanityTypes';
import PostTeasersCarousel from '../../PostTeasersCarousel';
import { PostTeasersSectionProps } from './types';
import { useLatestBlogs, usePostId } from './hooks';

const PostTeasersSection: React.VFC<PostTeasersSectionProps> = ({
  title,
  link: labeledLink,
  withAllPosts,
  articles,
}) => {
  const postId = usePostId();

  const data = useLatestBlogs(postId || '');

  const { link, buttonLabel } = labeledLink ?? {};
  
  const itemsData = (articles ?? []).map((post) => {
    const { _key, publishDate, images, title: postTitle, firstName, lastName, slug } = post ?? {};
    const { mobileImage, image } = images?.[0] || {};

    return {
      key: _key ?? '',
      image: mobileImage ? getImage(mobileImage) : getImage(image),
      title: postTitle ?? '',
      date: publishDate ? format(new Date(publishDate), 'dd MMMM, yyyy') : '',
      link: { to: slug?.current ?? '' },
      author: { firstName: firstName ?? '', lastName: lastName ?? '' },
    };
  });

  return (
    <PostTeasersCarousel
      data={{
        title: title ?? '',
        link: getHref(link),
        linkText: buttonLabel ?? '',
        items: withAllPosts ? data : itemsData,
      }}
    />
  );
};

export const fragment = graphql`
  fragment PostTeasersSectionFragment on SanityPostTeasersSection {
    _key
    _type
    title
    link {
      buttonLabel
      link {
        ...LinkFragment
      }
    }
    withAllPosts
    articles {
      _key
      id
      i18n_lang
      title
      slug {
        current
      }
      images {
        _key
        mobileImage {
          ...ImageFragment
        }
        image {
          ...ImageFragment
        }
      }
      firstName
      lastName
      publishDate
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default PostTeasersSection;
