import Slider from 'react-slick';
import styled from 'styled-components';

import media from '../../../common/MediaQueries';
import Heading from '../../molecules/Heading';

export const PostTeasersCarouselHeading = styled(Heading)`
  max-width: 22rem;
`;

export const PostTeasersCarouselWrapper = styled.div`
  margin-top: 2rem;

  @media ${media.tablet} {
    margin-top: 5rem;
  }

  .slick-slide,
  .slick-slide > div,
  .slick-slide > div > div {
    height: 100%;
  }

  .slick-track {
    display: flex;
  }

  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    position: absolute;
    bottom: 0;
  }

  .slick-arrow.slick-prev {
    right: 5rem;
  }

  .slick-arrow.slick-next {
    right: 2.5rem;
  }
  .slick-dots {
    padding-top: 2rem;
    height: 4rem;
  }

  .slick-list {
    margin: 0 -1.5rem;
  }

  .slick-slide > div {
    padding: 0 1.5rem;
  }
`;

export const PostTeasersCarouselItem = styled.div``;
